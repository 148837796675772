import React from 'react'
import Helmet from 'react-helmet'
import Thumbnail from '../assets/images/thumbnail.png'
import {
  url,
  defaultDescription,
  giftCardDescription,
  menuDescription,
  social,
  defaultTitle,
  giftCardTitle,
  menuTitle,
  socialLinks,
  address,
  contact,
  legalName,
  foundingDate,
  logo,
} from '../data/config'

const SEO = ({
  title = '',
  type = 'development',
  description = '',
  location = '',
}) => {
  const _title_getter = () => {
    return defaultTitle
  }
  const _title = _title_getter()
  const _description_getter = () => {
    return defaultDescription
  }
  const _description = _description_getter()

  const structuredDataOrganization = `{
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "Nader Khalil"
		},{
			"@type": "Person",
			"name": "Alec Fong"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.zipCode}"
		},
		"sameAs": [
			"${socialLinks.twitter}",
			"${socialLinks.facebook}",
			"${socialLinks.linkedin}",
			"${socialLinks.instagram}",
		]
  	}`

  return (
    <Helmet>
      <meta name="title" content={_title} />
      <meta name="description" content={_description} />
      <meta name="image" content={Thumbnail} />

      <meta property="og:url" content={`${url}${location}/?ref=brev.dev`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={_title} />
      <meta property="og:description" content={_description} />
      <meta property="og:image" content={Thumbnail} />
      <meta property="fb:admins" content={social.facebook} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={socialLinks.twitter} />
      <meta name="twitter:site" content={social.twitter} />
      <meta name="twitter:title" content={defaultTitle} />
      <meta name="twitter:description" content={defaultDescription} />
      <meta name="twitter:image:src" content={Thumbnail} />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={socialLinks.google} />
      <title>{_title}</title>
      <html lang="en" dir="ltr" />
    </Helmet>
  )
}
export default SEO
