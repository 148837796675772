module.exports = {
  defaultTitle: 'Brev.Dev',
  menuTitle: 'Brev.Dev',
  //   logo: 'https://www.paneau.io/favicon/favicon-512.png',
  author: 'Brev.Dev 2020',
  url: 'https://brev.dev',
  legalName: 'Brev.Dev',
  defaultDescription:
    "A reliable scalable framework that let's you code directly into the cloud.",
  socialLinks: {
    facebook: 'https://www.facebook.com/brev/',
    // linkedin: 'https://www.linkedin.com/company/paneau/',
    instagram: 'https://www.instagram.com/brev/',
    twitter: 'https://twitter.com/brev',
  },
  social: {
    facebook: 'brev',
    twitter: '@brev',
  },
  //   googleAnalyticsID: 'UA-160307884-1',
  themeColor: '#ff5c6c',
  backgroundColor: '#fffcf9',
  address: {
    city: 'San Francisco',
    region: 'California',
    country: 'USA',
    zipCode: '94133',
  },
  contact: {
    email: 'nader+hello@brev.dev',
  },
  foundingDate: '2020',
}
